export const assetMap = {
    'eth': {
        'ticker': "ETH",
        'action': "toWeth",
        'convertibleTo': "WETH"
    },
    'weth': {
        'ticker': "WETH",
        'action': "toEth",
        'convertibleTo': "ETH",
    },
}