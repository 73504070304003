import { ethers } from "ethers";
import { getWethContractAddress } from '../constants/address';
import wethAbi from '../abi/weth.json';

export let provider;
let wethContract;
let signer;
let accountAddress;

export async function initEthers(web3provider) {
    provider = new ethers.providers.Web3Provider(web3provider)
    signer = provider.getSigner(0);
    accountAddress = await signer.getAddress();

    const network = await provider.getNetwork()
    wethContract = new ethers.Contract(getWethContractAddress(network.name), wethAbi.result, provider);
}

export async function swapAsset(action, amount) {
    const signer = provider.getSigner(0);
    const wethContractWithSigner = wethContract.connect(signer);

    if (action === "toWeth") {
        await wethContractWithSigner.deposit({ value: ethers.utils.parseEther(amount) }).then((response) => {
            console.log(response);
        });
    } else if (action === "toEth") {
        await wethContractWithSigner.withdraw(ethers.utils.parseEther(amount)).then((response) => {
            console.log(response);
        });
    }
}

export async function getAssetBalance(assetTicker) {
    let balance;

    if (assetTicker === "ETH") {
        balance = await provider.getBalance(accountAddress)
            .then((response) => response.toString());
    } else if (assetTicker === "WETH") {
        const wethContractWithSigner = wethContract.connect(signer);
        balance = await wethContractWithSigner.balanceOf(accountAddress)
            .then((response) => response.toString());
    }
    
    return balance;
}