import React, { useState } from 'react';

import { assetMap } from '../../constants/assets';
import { useContext } from 'react';
import { swapAsset } from '../../utils/eth';

import { ServicesContext } from '../../context/ServicesProvider';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const AssetCard = (props) => {
    const balanceContext = useContext(ServicesContext);
    const assetData = assetMap[props.asset]
    const [nominal, setNominal] = useState("");

    async function clickHandler() {
        await swapAsset(assetData.action, nominal)
        setNominal("")
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title className="text-center">
                    {assetData.ticker}: <span>{balanceContext.balance[assetData.ticker]}</span>
                </Card.Title>
                <Row className="my-2">
                    <Col xs={{ span: 4, offset: 4, }}>
                        <input 
                            value={nominal}
                            onChange={e => setNominal(e.target.value)}
                            placeholder="0" 
                            className="form-control"
                            />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm={12} className="text-center">
                        <Button onClick={clickHandler}>Convert to {assetData.convertibleTo}</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default AssetCard;