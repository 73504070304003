import { useState, useEffect }  from 'react';
import { ethers } from "ethers";

import { provider, getAssetBalance, initEthers } from '../utils/eth';

const useBalance = () => {
    const [balance, setBalance] = useState({
        ETH: "-",
        WETH: "-"
    });

    useEffect(() => {
        async function initialize() {
            // const web3 = new Web3(provider);
            // try {
        //         if (typeof  window.ethereum !== undefined) {
        //             await window.ethereum.enable();
        //             await initEthers();
        //             await getBalance();
                    
                    // provider.on("block", (_) => {
        //                 getBalance();
                    // });
        //         } else {
        //             throw new Error("No injected web3 found")
        //         }
        //     } catch (e) {
        //         console.log(e)
        //         throw Error(e.message)
        //     }
        }
        // if (provider !== undefined) {
        //     provider.on("block", (_) => {
        //         getBalance();
        //     });
        // }
        initialize();
    }, []);

    const getBalance = async () => {
        const ethBalance = await getAssetBalance("ETH");
        const wethBalance = await getAssetBalance("WETH");

        setBalance({
            ETH: parseFloat(ethers.utils.formatEther(ethBalance).toString()).toFixed(3),
            WETH: parseFloat(ethers.utils.formatUnits(wethBalance, 18).toString()).toFixed(3),
        })
    }

    return { balance, getBalance };
};

export default useBalance;