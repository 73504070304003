import React, { createContext } from 'react';
import  useBalance from '../hooks/useBalance';

export const ServicesContext = createContext();

const ServicesProvider = ({ children }) => {
    return (
        // <ServicesContext.Provider value={[state, dispatch]}>
        <ServicesContext.Provider value={useBalance()}>
            {children}
        </ServicesContext.Provider>
    );
};

export default ServicesProvider;