import React from 'react';

import ServicesProvider from './context/ServicesProvider';

import AssetCard from './components/AssetCard/AssetCard';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { provider, getAssetBalance, initEthers } from './utils/eth';
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const providerOptions = {
  walletconnect: {
      package: WalletConnectProvider, // required
      options: {
      infuraId: "INFURA_ID" // required
      }
  }
};

const web3Modal = new Web3Modal({
  // network: "mainnet", // optional
  // cacheProvider: true, // optional
  providerOptions // required
});

function App() {

  const openWeb3Modal = async () => {
    // console.log("!?")
    const provider = await web3Modal.connect();
    // const web3 = new Web3(provider);
    await initEthers(provider);
    // await getBalance();
                    
    // provider.on("block", (_) => {
    //     getBalance();
    // });
  }

  return (
    <ServicesProvider>
      <div className="App">
        <Container>
          <Row className="py-5">
            <Col xs={12} className="text-right">
              <Button onClick={openWeb3Modal}>Connect</Button>
            </Col>
            <Col xs={12} className="text-center">
              <h1>WRAPPED ETHER</h1>
            </Col>
            <Col xs={12} className="mt-4">
              <Row>
                <Col sm={6} className="mt-2">
                  <AssetCard asset="eth"/>
                </Col>
                <Col sm={6} className="mt-2">
                  <AssetCard asset="weth"/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </ServicesProvider>
  );
}

export default App;
